import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIcon from '@material-ui/icons/Assignment';



export const mainListItems = (


  <div>
    <ListItem button style={{color:"rgb(209, 63, 143)"}} component="a" href="./Dashboard">
      <ListItemIcon style={{color:"rgb(209, 63, 143)"}}>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    {/* <ListItem button>
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
    </ListItem> */}
    <ListItem button style={{color:"rgb(209, 63, 143)"}} component="a" href="./Issues" >
      <ListItemIcon style={{color:"rgb(209, 63, 143)"}}>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Issues" />
    </ListItem>
    {/* <ListItem button>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItem> */}
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Third-Party Apps</ListSubheader>
    <ListItem button style={{color:"rgb(209, 63, 143)"}} component="a" href="https://myapps.microsoft.com/signin/Callcenter-dev-agents/f134604f-158e-4b56-9439-03a13b27c293?tenantId=4fb8ba03-f0c2-483b-bd36-67b298825edd" target="_blank">
      <ListItemIcon style={{color:"rgb(209, 63, 143)"}}>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Connect Dial" />
    </ListItem>
    <ListItem button style={{color:"rgb(209, 63, 143)"}} component="a" href="https://dev.callcenter.ttpsc.cloud/plugins/servlet/external-login" target="_blank">
      <ListItemIcon style={{color:"rgb(209, 63, 143)"}}>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Jira" />
    </ListItem>
  </div>
);