const config = {
  // apiGateway: {
  //   REGION: "YOUR_API_GATEWAY_REGION",
  //   URL: "YOUR_API_GATEWAY_URL",
  // },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_jYtsh3yG0",
    APP_CLIENT_ID: "217a24a1f949s714tu1d5u873p",
    DOMAIN: "callcenter-dev-app.auth.eu-central-1.amazoncognito.com",
    IDENTITY_POOL_ID: "YOUR_IDENTITY_POOL_ID",
    REDIRECTSIGNIN:"http://localhost:3000,https://app.dev.callcenter.ttpsc.cloud",
    REDIRECTSIGNOUT:"http://localhost:3000,https://app.dev.callcenter.ttpsc.cloud",
  }
};

export default config;