import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import "amazon-connect-streams";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits(props) {
  const classes = useStyles();
  


  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" style={{color: "rgb(123, 44, 133)"}} >
        Task issues
      </Typography>

      <Typography component="p" variant="h4">
        {props.issues?.length}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        Last issue: {props.issues.reverse()[0]?.fields.created}
      </Typography>
      <div>
        <Link color="primary" href={`https://dev.callcenter.ttpsc.cloud/browse/${props.issues.reverse()[0]?.key}`} target="_blank" rel="noreferrer">
          Open last issue
        </Link>
      </div>
    </React.Fragment>
  );
} 