// import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Container from '@material-ui/core/Container';
// import Amplify, { Auth, Hub } from 'aws-amplify';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   content: {
//     flexGrow: 1,
//     height: '100vh',
//     overflow: 'auto',
//   },
//   container: {
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   }
// }));


// export default function Login() {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//         <CssBaseline />
//         <main className={classes.content}>
//           <Container maxWidth="lg" className={classes.container}>
//             <button onClick={() => Auth.federatedSignIn()}>Federated Sign In</button>
//           </Container>
//       </main>
//     </div>

//   )
// }





import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, rgbToHex } from '@material-ui/core/styles';
import Amplify, { Auth, Hub } from 'aws-amplify';


function Login() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Login © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "rgb(209, 63, 143)",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "rgb(123, 44, 133)",
    hover: { 
      backgroundColor: "rgb(123, 44, 133)"
    }
  },

}));

export default function SignInSide() {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={2} md={4} />
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
      <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/logPSC.png'})`, backgroundRepeat: 'no-repeat', width: '250px', height: '60px', margin:'20px 20px 20px 30px'}}>
      </div>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>

            <Button
              onClick={() => Auth.federatedSignIn()}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{backgroundColor: "rgb(123, 44, 133)"}}
            >
              Sign In
            </Button>
            {/* <button onClick={() => Auth.federatedSignIn()}>Federated Sign In</button> */}
            <Grid container>
            </Grid>
            <Box mt={5}>
              <Login />
            </Box>
          </form>
        </div>
      </Grid>
      <Grid item xs={false} sm={2} md={4} />
    </Grid>
  );
}