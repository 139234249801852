import React, { useEffect, useState, Component } from 'react';
import Dashboard from './Components/Dashboard';
import Login from './Components/Login';
import Issues from './Components/Issues';
import Amplify, { Auth, Hub } from 'aws-amplify';
import config from './config';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { unmountComponentAtNode } from 'react-dom';
import axios from 'axios';


const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = config.cognito.REDIRECTSIGNIN.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = config.cognito.REDIRECTSIGNOUT.split(",");


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  oauth: {
    domain: config.cognito.DOMAIN,
    scope: ['email', 'openid'],
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    responseType: 'token'
  }
});


const apiUrl = axios.create({
  baseURL: 'https://dev.callcenter.ttpsc.cloud/rest/api/2/search'
})

class AppUrl extends Component{
  constructor() {
    super();
    apiUrl.get('/').then(res => {
      console.log(res.data)
    })
  }
}

function App() {
  const [user, setUser] = useState();


  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);


  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  if (user){
    return (
      <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/Dashboard">
            <Dashboard />
          </Route>
          <Route path="/Issues">
            <Issues />
          </Route>
          <Route path="/">
            <Dashboard />
          </Route>
        </Switch>
      </div>
    </Router>
      // <Dashboard></Dashboard>
    );
  } else{
    return (
      <Login />
    );
  }
}

export default App;
