import React from 'react';
import { hexToRgb, useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// Generate Sales Data
const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgb(123,44,133)',
    backgroundColor: 'rgb(123,44,133)',
  },

}));

export default function Chart(props) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" style={{color: "rgb(123, 44, 133)"}} >
                Today
      </Typography>
      <ResponsiveContainer>
        <LineChart
          data={props.chartData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary,}}
            >
              Issues
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}