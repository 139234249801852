import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import "amazon-connect-streams";
import axios from 'axios';
import Amplify, { Auth, Hub } from 'aws-amplify';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ttpsc.pl/">
        Transition Technologies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    },),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: 'rgb(123, 44, 133)',
  },
  menuButtonHidden: {
    display: 'none',
    color: 'rgb(123, 44, 133)',
  },
  title: {
    flexGrow: 1,
  },
  icons: {
    color: 'rgb(123, 44, 133)',

  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 500,
  },
  amazonContainer: {
    minWidth: '200px',
    height: '465px',
    minHeight: '400px',
    '& iframe': {
      display: 'block'
    }
  }
}));

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [issues, setIssues] = useState([]);
  const [clientNumber, setClientNumber] = useState();
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openA = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  


  function createData(time, amount) {
    return { time, amount };
  }

  const chartData = [
    createData('00:00', 0),
    createData('03:00', 2),
    createData('06:00', 1),
    createData('09:00', 3),
    createData('12:00', 4),
    createData('15:00', 0),
    createData('18:00', 1),
    createData('21:00', 0),
    createData('24:00', undefined),
  ];

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then(userData => setUser(userData));
  }, []);
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  
  useEffect(() => {
    // eslint-disable-next-line no-undef
    connect.core.initCCP(document.getElementById('connectHandler'), {
      ccpUrl: "https://callcenter-sso.my.connect.aws/connect/ccp-v2",
      loginUrl: "https://myapps.microsoft.com/signin/Callcenter-dev-agents/f134604f-158e-4b56-9439-03a13b27c293?tenantId=4fb8ba03-f0c2-483b-bd36-67b298825edd",
      loginPopup: true,
      loginPopupAutoClose: true,      // optional, defaults to `true`
      loginOptions: {                 // optional, if provided opens login in new window
        autoClose: true                // optional, defaults to `false`
      },
      softphone: {
        allowFramedSoftphone: true
      }
    });
  }, [])


  useEffect(() => {
    // eslint-disable-next-line no-undef
    connect.agent(function (agent) {

      agent.onStateChange(agentStateChange => {
        console.log(agentStateChange, agent.getStatus())

        if (agent.getStatus().name == 'Busy') {
              const fetchData = async () => {
                const result = await axios.get(
                  `https://k85c91p8zh.execute-api.eu-central-1.amazonaws.com/dev/get-issues/${clientNumber}`,
                );
                setIssues(result.data.issues)
                console.log(agentStateChange.newState, result.data.issues)
              }
              fetchData();
          
        } else if( agent.getStatus().name == 'AfterCallWork' ){
          setIssues([])
        }
      });
    })
  })
 

  useEffect(() => {
    // eslint-disable-next-line no-undef
    connect.contact((contact) => {
      contact.onAccepted(setClientNumber(contact.getAttributes().number.value))
    })
  })

  return (
    // <div style={{backgroundColor:"rgb(255, 255, 255)", backgroundImage: `url(${process.env.PUBLIC_URL + '/logPSC.png'})`, backgroundRepeat: 'no-repeat'}}>
    // </div>
    <div className={classes.root}>
      <CssBaseline />
      {/* <Toolbar className={classes.toolbar} style={{ backgroundImage : `url(${process.env.PUBLIC_URL + '/logoPSC.png'})`, backgroundRepeat: 'no-repeat', width: '100%'}}></Toolbar> */}
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{backgroundColor:"rgb(255, 255, 255)"}}>
        {/* style= {{backgroundImage: `url(${process.env.PUBLIC_URL + '/logIn.png'})`, backgroundRepeat: 'no-repeat' }} */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/logPSC.png'})`, backgroundRepeat: 'no-repeat', padding:'0px, 0px, 0px, 0px', width: '250px', height: '60px', margin: '0,0,0,0'}}/>

            <Typography component="h5" variant="h9" style={{color: "rgb(123, 44, 133)", paddingLeft: '20px'}} noWrap className={classes.title} >
              Dashboard -  {user ? JSON.stringify(user.username)+'('+JSON.stringify(user.signInUserSession.idToken.payload.email)+')' : 'None'}                   
            </Typography>

          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit" className={classes.icons}>
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" className={classes.icons}>
                <ContactPhoneIcon />
            </IconButton>
            <IconButton color="inherit" className={classes.icons} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>  
            <AccountCircle />
              </IconButton>
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openA}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={() => Auth.signOut()}>Logout </MenuItem>
              </Menu>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              className={classes.icons}
              aria-label="show more"
              // aria-controls={mobileMenuId}
              aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={5} lg={5}>
              <Paper className={fixedHeightPaper}>
                <Chart chartData={chartData}/>
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits issues={issues} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                {/* <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Amazon connect
          </Typography> */}
                <div class="menu" id="connectHandler" className={classes.amazonContainer}>
                </div>
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders issues={issues} />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}