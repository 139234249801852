// import React, { useEffect, useState } from 'react';
import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';


function preventDefault(event) {
    event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

export default function Orders(props) {
   
    const classes = useStyles();
   
    return (
        <React.Fragment>
            <Typography component="h1" variant="h6" style={{color: "rgb(123, 44, 133)"}} >
                Issues
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Creator email</TableCell>
                        <TableCell>Issue type</TableCell>
                        <TableCell>Issue Number</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Jira external link</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.issues.map((issue) => (
                        <TableRow key={issue.key}>
                            <TableCell>{issue.fields.creator.emailAddress}</TableCell>
                            <TableCell>{issue.fields.priority.name}</TableCell>
                            <TableCell>{issue.key}</TableCell>
                            <TableCell>{issue.fields.status?.name}</TableCell>
                            <TableCell align="right"><a href={`https://dev.callcenter.ttpsc.cloud/browse/${issue.key}`} target="_blank" rel="noreferrer">Open in jira</a></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault} >
                <Typography component="h8" variant="h8" style={{color: "rgb(123, 44, 133)"}} >
                    See more orders
                </Typography>
                    {/* See more orders */}
        </Link>
            </div>
        </React.Fragment>
    );
}