import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {withStyles, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, secondaryListItems } from './listItems';
import "amazon-connect-streams";
import MailIcon from '@material-ui/icons/Mail';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Amplify, { Auth, Hub } from 'aws-amplify';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ttpsc.pl/">
        Transition Technologies
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    },),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: 'rgb(123, 44, 133)',
  },
  menuButtonHidden: {
    display: 'none',
    color: 'rgb(123, 44, 133)',
  },
  title: {
    flexGrow: 1,
  },
  icons: {
    color: 'rgb(123, 44, 133)',

  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 500,
  },
  amazonContainer: {
    minWidth: '200px',
    height: '465px',
    minHeight: '400px',
    '& iframe': {
      display: 'block'
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(123,44,133)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Issues() {
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [allIssues,setAllIssues]=useState([]);
 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openA = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then(userData => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    getUser().then(userData => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
  }

  
  const getDataIssues=()=>{
    fetch('https://dev.callcenter.ttpsc.cloud/rest/api/2/search'
    ,{
      headers : { 
        'Authorization': 'Basic Y29ubmVjdEJvdDpvWjY1NmNjeEhVbjA=',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson.issues);
        setAllIssues(myJson.issues)
      });
  }
  useEffect(()=>{
    getDataIssues()
  },[])





  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{backgroundColor:"rgb(255, 255, 255)"}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <div style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/logPSC.png'})`, backgroundRepeat: 'no-repeat', padding:'0px, 0px, 0px, 0px', width: '250px', height: '60px', margin: '0,0,0,0'}}/>

            <Typography component="h5" variant="h9" style={{color: "rgb(123, 44, 133)", paddingLeft: '20px'}} noWrap className={classes.title} >
              Issues -  {user ? JSON.stringify(user.username)+'('+JSON.stringify(user.signInUserSession.idToken.payload.email)+')' : 'None'}                   
            </Typography>

          <div className={classes.sectionDesktop}>
            <IconButton aria-label="show 4 new mails" color="inherit" className={classes.icons}>
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" className={classes.icons}>
                <ContactPhoneIcon />
            </IconButton>
            <IconButton color="inherit" className={classes.icons} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>  
            <AccountCircle />
              </IconButton>
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openA}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={() => Auth.signOut()}>Logout </MenuItem>
              </Menu>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              className={classes.icons}
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography component="h1" variant="h4" style={{color: "rgb(123, 44, 133)"}} >
                Issues
            </Typography><br/>
              
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                    <TableRow>
                        <StyledTableCell>Creator email</StyledTableCell>
                        <StyledTableCell align="center">Issue type</StyledTableCell>
                        <StyledTableCell align="center">Issue number</StyledTableCell>
                        <StyledTableCell align="center">Status</StyledTableCell>
                        <StyledTableCell align="center">Jira external link</StyledTableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {allIssues.map((issue) => (
                        <StyledTableRow key={issue.key}>
                        <StyledTableCell component="th" scope="row">{issue.fields.creator.emailAddress}</StyledTableCell>
                        <StyledTableCell align="center">{issue.fields.priority.name}</StyledTableCell>
                        <StyledTableCell align="center">{issue.key}</StyledTableCell>
                        <StyledTableCell align="center">{issue.fields.status?.name}</StyledTableCell>
                        <StyledTableCell align="center"><a href={`https://dev.callcenter.ttpsc.cloud/browse/${issue.key}`} target="_blank" rel="noreferrer">Open in jira</a></StyledTableCell>
                        </StyledTableRow>
                    ))} 
                    </TableBody>
                </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}